<template>
  <div class="select-address-wrapper">
    <div class="select-address">
      <div class="select-address-label">活动区域：</div>
      <div class="select-address-city" @click="select">
        <span class="cityName">{{ address }}</span>
        <img :src="require('./images/arrow.png')" alt="" class="arrow" />
      </div>
    </div>

    <van-popup
      closeable
      v-model="show"
      position="bottom"
      style="height: 90%; width: 100%"
    >
      <div class="city-wrapper">
        <div class="city-header">
          <img :src="require('./images/location.png')" alt="" />
          当前定位城市
        </div>
        <div class="location">
          <van-row gutter="10">
            <van-col
              span="6"
              style="padding-right: 7.5px"
              @click="selectCity()"
            >
              <div class="city van-ellipsis">{{ locationCity }}</div>
            </van-col>
          </van-row>
          <span class="refresh" @click="refresh">重新定位</span>
        </div>
        <div class="city-header">
          <img :src="require('./images/citys.png')" alt="" />
          参与活动城市
        </div>
        <div class="city-list">
          <van-row gutter="10">
            <van-col span="6" style="padding-right: 7.5px" @click="selectAll">
              <div class="city van-ellipsis">全部区域</div>
            </van-col>
          </van-row>
          <van-row gutter="10">
            <van-col span="6" v-for="city in cityList">
              <div
                class="city van-ellipsis"
                :key="city.value"
                @click="selectCity(city)"
              >
                {{ city.label }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { activityAreaList } from "@/api/activity1";
import { Popup as VanPopup, Col as VanCol, Row as VanRow } from "vant";
import { mapGetters } from "vuex";
/* 直辖市citycode */
const citycodes = ["010", "021", "022", "023"];
export default {
  components: {
    VanPopup,
    VanRow,
    VanCol,
  },
  data() {
    return {
      show: false,
      cityList: [],
    };
  },
  computed: {
    ...mapGetters(["regeocode", "selectedAddress"]),
    address() {
      return this.selectedAddress ? this.selectedAddress.city : "定位失败";
    },
    locationCity() {
      if (!this.regeocode) return undefined;
      const {
        addressComponent: { citycode, city, district, province },
      } = this.regeocode;
      /* 如果city不存在,判断citycode是否属于直辖市,如果是直辖市使用province字段代表城市,否则代表当前城市为省直辖县,使用district字段,如果city存在直接使用city */
      if (city) return city;
      return citycodes.includes(citycode) ? province : district;
    },
  },
  methods: {
    select() {
      this.show = true;
    },
    selectCity(city) {
      this.$store.commit("set_selectedAddress", {
        adCode: city ? city.value : this.regeocode.addressComponent.citycode,
        city: city ? city.label : this.address,
      });
      this.show = false;
    },
    selectAll() {
      this.$store.commit("set_selectedAddress", {
        adCode: undefined,
        city: "全部区域",
      });
      this.show = false;
    },
    refresh() {
      wx.getLocation({
        success: function (res) {
          console.log("res", res);
          const { latitude, longitude } = res;
          this.$store.commit("set_location", res);
          getRegeo({
            latitude,
            longitude,
          })
        },
        cancel() {},
      });
    },
  },
  created() {
    const activityId = this.$route.query.activityId;
    activityAreaList({ activityId }).then(({ data }) => {
      this.cityList = data;
    });
  },
};
</script>

<style lang='scss'>
.select-address-wrapper {
  .select-address {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    line-height: 20px;

    &-label {
      font-size: 14px;
      color: #657381;
    }

    &-city {
      display: flex;
      align-items: center;
      .cityName {
        font-size: 16px;
        font-weight: bolder;
        color: #29292b;
        margin-right: 4px;
      }
      .arrow {
        width: 16px;
      }
    }
  }
  .city-wrapper {
    padding: 32px 16px;
    .city-header {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bolder;
      line-height: 22px;
      color: #29292b;
      margin-bottom: 14px;
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
    .location {
      margin-bottom: 22px;
      position: relative;
      .refresh {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        line-height: 20px;
        color: #18c0bf;
      }
    }
    .city-list {
      .city {
        margin-bottom: 10px;
      }
    }
  }

  .city {
    text-align: center;
    padding: 0 5px;
    height: 36px;
    line-height: 36px;
    background: #f5f9fc;
    border-radius: 6px;
    font-size: 14px;
    color: #29292b;
  }
}
</style>
