<template>
  <div class="clinic" @click="toDetail">
    <div class="clinic-inner van-hairline--bottom">
      <VanImage
        class="clinic-img"
        fit="cover"
        :radius="8"
        :width="64"
        :height="64"
        :src="clinic.avatarPicture && clinic.avatarPicture.smallest"
      >
        <VanImage
          slot="error"
          :width="64"
          :height="64"
          :src="require('./images/avatar.png')"
        />
      </VanImage>
      <div class="clinic-info">
        <div class="clinic-name">{{ clinic.clinicName }}</div>
        <div class="tips">
          <span class="tip" v-if="clinic.flag == 0">可预约</span>
          <span class="tip" v-if="clinic.flag == 2">可排队</span>
          <span class="tip" style="color:#FA695D;background:#FFE8E7;">接种有奖</span>
        </div>
        <div class="clinic-text">
          <div class="text van-ellipsis">
            {{ clinic.address }}
          </div>
          <div class="distance">{{ clinic.distance }}km</div>
        </div>
        <div class="clinic-text">
          <div class="van-ellipsis">
            {{ clinic.inoculationTime || "暂未维护门诊开放日" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Image as VanImage } from "vant";
export default {
  components: {
    VanImage,
  },
  data() {
    return {
      nFlag: false,
    };
  },
  props: {
    clinic: {
      default: () => ({}),
    },
    vaccineGroupId: {
      default: undefined,
    }
  },
  created() {
    console.log("clinic", this.clinic);
  },
  methods: {
    toDetail() {
      const _this = this;
      if (this.nFlag) return;
      this.nFlag = true;
      wx.miniProgram.navigateTo({
        url:`/pages/index/clinic/newIndex?a=${_this.clinic.clinicId}&b=${_this.vaccineGroupId}`,
        complete() {
          _this.nFlag = false;
        },
      });
    },
  },
};
</script>

<style lang='scss'>
.clinic {
  padding: 0 16px;
  background-color: #ffffff;

  .clinic-inner {
    display: flex;
    padding: 16px 0;
  }

  &:active,
  &:hover {
    background-color: rgba(212, 212, 212, 0.4);
  }

  .clinic-img {
    margin-right: 16px;
  }

  .clinic-info {
    padding-top: 2px;
    flex: 1;
    width: 0;

    .clinic-name {
      color: #29292b;
      font-size: 16px;
      font-weight: bolder;
      line-height: 20px;
      margin-bottom: 6px;
    }
    .tips {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      font-size: 12px;
      .tip {
        display: flex;
        flex:none;
        justify-content: center;
        align-items:center;
        margin-right: 4px;
        font-weight: bold;
        color: #18c0bf;
        padding: 0 4px;
        height: 19px;
        background: #ddfbfb;
        border-radius: 2px;
      }
    }

    .clinic-text {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #93a0ac;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      .text {
        margin-right: 9px;
        flex: 1;
      }

      .distance {
        font-weight: bold;
        flex: none;
        color: #18c0bf;
      }
    }
  }
}
</style>
