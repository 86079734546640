import Vue from 'vue'
import VueRouter from 'vue-router';
import {
  getToken,
  setToken
} from '@/utils/auth'
import wx from "weixin-js-sdk";
import {
  getTicket
} from "@/api/common";
import {
  getRegeo
} from "@/api/map";
import store from "@/store"
import qs from 'qs';

import Activity1 from '../views/Activity1';
import ClinicList from '../views/ClinicList';

import Activity2 from '../views/Activity2';
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/activity1',
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/activity1',
    name: 'activity1',
    component: Activity1
  },
  {
    path: '/clinicList',
    name: 'clinicList',
    component: ClinicList
  },
  {
    path: '/activity2',
    name: 'activity2',
    component: Activity2
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    store.dispatch('getInfo').finally(() => {
      next()
    })
  } else {
    const {
      query,
      path
    } = to;
    console.log('beforeEach', to)
    let queryParams = {};
    if (query.data) {
      queryParams = qs.parse(decodeURIComponent(query.data));
    }
    const {
      token,
      location,
      regeocode,
      selectedAddress,
      ...rest
    } = queryParams;
    console.log('queryParams', queryParams)



    store.commit('set_token', token)
    if (!store.getters.location) store.commit('set_location', location)
    if (!store.getters.regeocode) store.commit('set_regeocode', regeocode)
    if (!store.getters.selectedAddress) store.commit('set_selectedAddress', selectedAddress)

    next({
      replace: true,
      path,
      query: {
        ...rest
      }
    })
  }
})

router.afterEach(() => {
  const url = window.location.href;

  /* 判断是否是ios */
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  store.commit('set_isIos', isIos);
  console.log('isIos', isIos);

  if (isIos && !store.getters.entryUrl) {
    store.commit('set_entryUrl', url)
  }

  getTicket({
    url: isIos ? store.getters.entryUrl : url,
  }).then(({
    data
  }) => {
    const {
      appId,
      timestamp,
      nonceStr,
      signature
    } = data;
    wx.config({
      // debug: true,
      appId,
      timestamp,
      nonceStr,
      signature,
      jsApiList: ["getLocation"], // 必填，需要使用的 JS 接口列表
    });
  });

})

export default router