<template>
  <div class="activity1">
    <div class="border-wrapper info">
      <div class="border-wrapper-inner">
        <img class="step" :src="require('./images/step.png')" alt="" />
        <img
          v-if="isBefore"
          class="btn"
          :src="require('./images/btn2.png')"
          alt=""
        />
        <img
          v-else-if="isAfter || info.flag === 1 || info.state === 1"
          class="btn"
          :src="require('./images/btn3.png')"
          alt=""
        />
        <img
          v-else
          class="btn"
          :src="require('./images/btn.png')"
          alt=""
          @click="toClinicList"
        />
        <div class="total">{{ info.count }} 位用户已参与，已发 {{ info.amount }} 份奖励</div>
      </div>
    </div>
    <img :src="require('./images/intr.png')" alt="" class="intr" />
    <div class="bottom">
      <img :src="require('./images/bottom.png')" alt="" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { activityDetail } from '@/api/common'
export default {
  data() {
    return {
      info: {
        beginTime: undefined,
        count: 0,
        amount: 0,
        endTime: undefined,
        vaccineGroupId: undefined,
        state: 0
      }
    };
  },
  computed: {
    isBefore() {
      if(!this.info.beginTime) return false;
      return dayjs().isBefore(dayjs(this.info.beginTime).startOf('day'))
    },
    isAfter() {
      if(!this.info.endTime) return false;
      return dayjs().isAfter(dayjs(this.info.endTime).endOf('day'))
    },
  },
  components: {},
  methods: {
    toClinicList() {
      this.$router.push({
        path: "/clinicList",
        query: {
          ...this.$route.query,
        },
      });
    },
    getInfo() {
      activityDetail({ activityId: this.$route.query.activityId }).then(({ data }) => {
        this.info = data;
      })
    }
  },
  created() {
    this.getInfo()

    
  },
  
};
</script>

<style lang='scss'>
.activity1 {
  background: #ff8356 url(./images/bg.png) top/100% auto no-repeat;
  padding: 416px 10px 0;
  .border-wrapper {
    position: relative;
    font-size: 0;
    height: 200px;
    background-image: url(./images/bg2.png);
    background-repeat: repeat;
    background-size: 100% auto;
    &-inner {
      box-sizing: border-box;
      width: 100%;
      position: absolute;
      z-index: 200;
      top: -5px;
      left: 0;
      padding: 0 16px;
    }
    .step,
    .btn {
      width: 100%;
    }
    .step {
      margin-bottom: 18px;
    }
    .btn {
      margin-bottom: 12px;
    }
    .total {
      text-align: center;
      font-size: 12px;
      font-weight: bolder;
      line-height: 17px;
      color: #8d1a13;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 100%;
      height: 40px;
      position: absolute;
      left: 0;
      top: -28px;
      background: url(./images/bg1.png) top/100% auto no-repeat;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 40px;
      position: absolute;
      left: 0;
      bottom: -28px;
      background: url(./images/bg3.png) bottom/100% auto no-repeat;
    }
  }

  .info {
    margin-bottom: 48px;
  }
  .intr {
    width: 100%;
    margin-bottom: 16px;
  }
  .bottom {
    text-align: center;
    img {
      width: 182px;
    }
  }
}
</style>
