import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters';

import app from './modules/app.js'
import user from './modules/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    user
  },
  getters
})