import request from '@/utils/request'

/* 微信jssdk授权 */
export function getTicket(data) {
    return request({
        url: '/mp/getTicket',
        method: 'post',
        data
    })
}

/* 获取用户信息 */
export function getWxUser() {
    return request({
        url: '/api/user/getWxUser',
        method: 'post',
    })
}

/* 活动详情 */
export function activityDetail(params) {
    return request({
        url: '/mp/activityDetail',
        method: 'get',
        params
    })
}
