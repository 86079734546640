import {
	getWxUser
} from '@/api/common'

const user = {
	state: {
		// 用户信息
		userInfo: {},
	},
	mutations: {
		set_user: (state, userInfo) => {
			state.userInfo = userInfo;
		},
	},
	actions: {
		// 获取用户信息
		getInfo({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				getWxUser().then(res => {
					commit('set_user', res.data)
					resolve(res)
				}).catch(err => {
					reject(err)
				})
			})
		},
	}
}

export default user
