const app = {
    state: {
        /* 判断是否是ios系统 */
        isIos: false,
        /* 第一次进入时url */
        entryUrl: undefined,
        token: undefined,
        /* 用户当前定位 */
        location: undefined,
        /* 定位地理信息 */
        regeocode: undefined,
        /* 
        	当前搜索区域信息 
        	{
        		adCode: string;
        		city: string;
        	}
        */
        selectedAddress: undefined,
    },
    mutations: {
        set_isIos: (state, isIos) => {
            state.isIos = isIos;
        },
        set_entryUrl: (state, entryUrl) => {
            state.entryUrl = entryUrl;
        },
        set_token: (state, token) => {
            state.token = token;
        },
        set_location: (state, location) => {
            state.location = location;
        },
        set_regeocode: (state, regeocode) => {
            state.regeocode = regeocode;
        },
        set_selectedAddress: (state, selectedAddress) => {
            state.selectedAddress = selectedAddress;
        },
    }
}

export default app;