import request from '@/utils/request'

/* 获取活动区域 */
export function activityAreaList(params) {
    return request({
        url: '/mp/activityAreaList',
        method: 'get',
        params
    })
}

/* 门诊列表 */
export function clinicList(params) {
    return request({
        url: '/mp/clinicList',
        method: 'get',
        params
    })
}


