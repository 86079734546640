import axios from 'axios'
import store from '@/store'
import {
  getToken
} from '@/utils/auth.js'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  // timeout: 10000
})
// request拦截器
service.interceptors.request.use(
  config => {
    const token = store.getters.token;
    if (token) {
      config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
      // 时间戳，针对ie浏览器请求一次，不请求获取缓存的bug
      config.params = {
        t: Date.parse(new Date()) / 1000,
        ...config.params
      }


    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(res => {
    const code = res.data.code

    if (!code) return res.data

    if (code === 401) {
      // MessageBox.confirm(
      //   '您的登录状态已过期，请重新登录',
      //   '系统提示', {
      //     confirmButtonText: '重新登录',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //     showCancelButton: false,
      //     showClose: false,
      //     closeOnClickModal: false,
      //     closeOnHashChange: false,
      //     closeOnPressEscape: false
      //   }
      // ).then(() => {
      //   store.dispatch('LogOut').then(() => {
      //     location.reload() // 为了重新实例化vue-router对象 避免bug
      //   })
      // })
    } else if (code !== 200) {
      // Notification.error({
      //   title: res.data.msg
      // })
      return Promise.reject(res.data)
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
