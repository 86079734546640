<template>
  <div class="clinic-list-wrapper">
    <SelectAddress />
    <div class="clinic-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <Clinic
          v-for="clinic in list"
          :key="clinic.clinicId"
          :clinic="clinic"
          :vaccineGroupId="vaccineGroupId"
        />
      </van-list>
    </div>
  </div>
</template>

<script>
import SelectAddress from "@/components/SelectAddress/SelectAddress.vue";
import Clinic from "./clinic.vue";
import { clinicList, activityDetail } from "@/api/activity1";
import { mapGetters } from "vuex";
import { List as VanList } from "vant";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      queryParams: {
        pageSize: 10,
        pageNum: 1,
      },
      list: [],
      isInit: false,

      vaccineGroupId: undefined,
    };
  },
  computed: {
    ...mapGetters(["location", "selectedAddress"]),
  },
  watch: {
    selectedAddress: {
      immediate: true,
      handler(val, oldVal) {
        console.log(val, oldVal);
        if (!this.isInit) return;
        if (!val) return;
        if (!oldVal || (val && oldVal && val.adCode !== oldVal.adCode)) {
          this.queryParams.pageNum = 1;
          this.loading = true;
          this.list = [];
          this.finished = false;
          this.getList();
        }
      },
    },
  },
  components: {
    SelectAddress,
    Clinic,
    VanList,
  },
  created() {
    this.getInfo();
  },
  methods: {
    getList() {
      const params = {
        ...this.queryParams,
        activityId: this.$route.query.activityId,
        longitude: this.location.longitude,
        latitude: this.location.latitude,
        adCode: this.selectedAddress.adCode,
      };

      clinicList(params).then(({ rows, noMore }) => {
        this.loading = false;
        this.list = [...this.list, ...rows];
        this.finished = noMore;
        this.isInit = true;
        this.queryParams.pageNum = noMore
          ? this.queryParams.pageNum
          : this.queryParams.pageNum + 1;
      });
    },
    onLoad() {
      this.getList();
    },
    getInfo() {
      activityDetail({ activityId: this.$route.query.activityId }).then(
        ({ data }) => {
          this.vaccineGroupId = data.vaccineGroupId;
        }
      );
    },
  },
};
</script>

<style lang='scss'>
html,
body {
  height: 100%;
  #app {
    height: 100%;
  }
}
.clinic-list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .clinic-list {
    height: 0;
    overflow: auto;
    flex: 1;
  }
}
</style>
